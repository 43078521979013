import Vue from "vue";
import VueRouter from "vue-router";
import Views from "../views";
import store from "../store";

Vue.use(VueRouter);

let routes = {
  auth: [
    // { path: '/dashboard', name: 'dashboard', component: Views.User.Dashboard },

    // test TO
    { path: "/epot", name: "epot", component: Views.User.Epot.EpotStart },
    { path: "/epot/end", name: "epot-end", component: Views.User.Epot.EpotEnd },
    {
      path: "/epot/preparation",
      name: "epot-preparation",
      component: Views.User.Epot.EpotPreparation,
    },
    {
      path: "/epot/specification",
      name: "epot-specification",
      component: Views.User.Epot.EpotSpecification,
    },
    {
      path: "/epot/:id/device-check",
      name: "epot-device-check",
      component: Views.User.Epot.EpotDeviceVerification,
    },
    {
      path: "/epot/:id/webcam-check",
      name: "epot-webcam-check",
      component: Views.User.Epot.EpotCheckCam,
    },
    {
      path: "/epot/:id/headset-check",
      name: "epot-headset-check",
      component: Views.User.Epot.EpotCheckHeadset,
    },
    {
      path: "/epot/:id/listening",
      name: "epot-listening",
      component: Views.User.Epot.EpotListening,
    },
    {
      path: "/epot/:id/structure",
      name: "epot-structure",
      component: Views.User.Epot.EpotStructure,
    },
    {
      path: "/epot/:id/reading",
      name: "epot-reading",
      component: Views.User.Epot.EpotReading,
    },

    // certficate
    {
      path: "/reward",
      name: "reward",
      component: Views.User.Certif.CertifList,
    },
    {
      path: "/certificate/:id/tracking",
      name: "certificate-tracking",
      component: Views.User.Certif.Tracking,
    },
    { path: "/viewer", name: "viewer", component: Views.User.Certif.Viewer },

    //account
    {
      path: "/account",
      name: "account",
      component: Views.User.Account.AccountSetting,
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: async () => await import("../views/privacy-policy/index.vue"),
    },
    //guide
    { path: "/guide", name: "guide", component: Views.User.Guide.GuideView },
  ],
  guest: [
    { path: "/", name: "login", component: Views.Auth.Login },
    {
      path: "/success-verify-email/:code",
      name: "success-verify-email",
      component: Views.Auth.SuccessVerif,
    },
    {
      path: "/registration",
      name: "registration",
      component: Views.Auth.Registration,
    },
    {
      path: "/password-send-email",
      name: "password-send-email",
      component: Views.Auth.PasswordSendEmail,
    },
    {
      path: "/success-send-email",
      name: "success-send-email",
      component: Views.Auth.SuccessSendEmail,
    },
    {
      path: "/password-reset/:code",
      name: "password-reset",
      component: Views.Auth.PasswordReset,
    },
    {
      path: "/success-reset-password",
      name: "success-reset-password",
      component: Views.Auth.SuccessResetPassword,
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: async () => await import("../views/privacy-policy/index.vue"),
    },
  ],
  default: [
    { path: "/sso", name: "sso-login", component: Views.Auth.SSOLogin },
  ],
};

let institusion = {};
if (
  localStorage.getItem("ytc_lms_auth") &&
  JSON.parse(localStorage.getItem("ytc_lms_auth")).institusion
) {
  institusion = JSON.parse(localStorage.getItem("ytc_lms_auth")).institusion;
}

if (institusion && institusion.id !== "") {
  let indexes = routes.auth
    .map(function (e) {
      return e.name;
    })
    .indexOf("reward");
  routes.auth.splice(indexes, 1);
} else {
  routes.auth;
}

routes.auth.forEach((route) => {
  route.meta = { ...route.meta, auth: true };
});
routes.guest.forEach((route) => {
  route.meta = { ...route.meta, guest: true };
});
routes = [...routes.auth, ...routes.guest, ...routes.default];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (from.meta.prevent_move) {
    alert("Mohon maaf, anda tidak bisa meninggalkan halaman ini");
    return next(false);
  }

  if (store.getters.isLoggedIn) {
    if (to.path == "/sso") {
      store.dispatch("logout");
      return next();
    }
    if (!store.getters.user.is_updated_profile && !['/account', '/privacy-policy'].includes(to.path)) {
      return next("/account");
    }
    if (to.path == "/" || to.meta.guest) {
      return next("/epot");
    }
  }
  if (["/account", "/privacy-policy"].includes(to.path) && !!to.query.token) {
    return next();
  }
  if (to.meta.auth && !store.getters.isLoggedIn) {
    localStorage.setItem("ytc_lms_redirect", location.pathname);
    return next("/");
  }

  return next();
});

export default router;
