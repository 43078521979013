<template>
  <v-card elevation="0" class="px-2 py-2 transparent">
    <section v-if="pageLoading" id="detail-kelas" class="py-5">
      <v-row justify="center">
        <v-col cols="8" class="my-4">
          <div class="text-center">Processing Data...</div>
          <v-progress-linear indeterminate />
        </v-col>
      </v-row>
    </section>
    <template v-else>
      <div class="mb-2 fs-24 color-state font-weight-bold">
        <span>Account</span>
      </div>
      <v-card-text class="px-0 bg-white">
        <v-card elevation="0" class="">
          <v-tabs id="" v-model="tab" background-color="transparent" class="">
            <v-tab class="text-capitalize">Informasi Pribadi</v-tab>
            <v-tab v-show="showEmail" class="text-capitalize"
              >Ubah Password</v-tab
            >
          </v-tabs>
          <v-divider class="mb-4"></v-divider>
          <v-tabs-items v-model="tab" class="setting transparent">
            <v-tab-item>
              <v-row>
                <v-col cols="12" md="2" class="">
                  <div class="text-center profile ma-auto">
                    <div
                      class="mb-3 pa-10 bg-grey rounded-circle d-inline-block fs-20"
                    >
                      <v-icon>ri-user-line</v-icon>
                    </div>
                    <!-- <p class="fs-20 color-state font-weight-medium">Username</p> -->
                  </div>
                </v-col>
                <v-col cols="12" md="7" class="ps-2">
                  <v-alert class="ms-4 fs-12 accent-blue me-2">
                    <div class="d-flex">
                      <v-icon class="me-2 color-blue align-start"
                        >ri-information-fill</v-icon
                      >
                      <p class="mb-0 color-blue">
                        Harap data di bawah ini diisi dengan informasi yang
                        sebenarnya dan tanpa kesalahan karena data yang sudah
                        terinput tidak dapat dirubah kembali dan data ini
                        merupakan data identitas yang akan tercetak pada
                        sertifikat. Jika terdapat kendala atau hal yang belum
                        dimengerti bisa hubungi admin AEPT via WhatsApp ke
                        0813-2614-9338
                        <!-- 0813-2614-9338. -->
                      </p>
                    </div>
                  </v-alert>
                  <v-alert
                    class="ms-4 text-capitalize"
                    type="error"
                    text
                    v-html="response"
                    v-show="response !== null"
                  >
                  </v-alert>
                  <v-alert
                    class="ms-4"
                    type="success"
                    text
                    v-html="responsesukses"
                    v-show="responsesukses !== null"
                  >
                  </v-alert>
                  <v-form
                    class="pb-5 ps-2"
                    ref="form"
                    v-model="valid"
                    lazy-validation
                  >
                    <!-- <v-row> -->
                    <v-col class="pb-0">
                      <span class="font-weight-medium"
                        >Foto Kartu Identitas (KTM/SIM/Kartu Keanggotaan Organisasi)</span
                      >
                      <span v-if="isImageKTM && !changeStatusKTM">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="red"
                              v-on="on"
                              v-bind="attrs"
                              @click="deleteImageKTM"
                            >
                              <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Hapus Foto</span>
                        </v-tooltip></span
                      >
                      <span class="float-right red--text font-italic caption"
                        >(Wajib)</span
                      >
                      <div class="mb-2" v-if="!changeStatusKTM">
                        <div
                          :class="['dropZone', draging ? 'dropZone-over' : '']"
                          @dragenter="draging = true"
                          @dragleave="draging = false"
                        >
                          <div
                            v-if="isImageKTM == ''"
                            class="dropZone-info"
                            @drag="onChange"
                          >
                            <span
                              class="fa fa-cloud-upload dropZone-title"
                            ></span>
                            <span class="dropZone-title"
                              >Seret dan letakkan foto Kartu Identitas disini</span
                            >
                            <div class="dropZone-upload-limit-info">
                              <div>atau</div>
                              <div></div>
                            </div>
                            <v-btn elevation="0" class="mt-5"
                              >Ambil Dari Komputer</v-btn
                            >
                          </div>
                          <div v-else class="dropZone-info">
                            <span
                              class="fa fa-cloud-upload dropZone-title"
                            ></span>
                            <v-img
                              class="mx-auto"
                              width="350"
                              height="230"
                              contain
                              :src="isImageKTM"
                            >
                            </v-img>
                          </div>
                          <form ref="fileUploaded">
                            <input
                              accept="image/x-png,image/gif,image/jpeg,image/jpg"
                              ref="inputFile"
                              type="file"
                              @change="onChange"
                            />
                          </form>
                        </div>
                      </div>

                      <div class="upload-container" v-else>
                        <div class="upload-content-img">
                          <v-img
                            class="mx-auto"
                            width="350"
                            height="230"
                            contain
                            :src="isImageKTM"
                          >
                          </v-img>
                        </div>
                      </div>
                    </v-col>
                    <v-col class="pb-0">
                      <div class="mb-2">
                        <span class="font-weight-medium"
                          >Foto Memegang Kartu Identitas (KTM/SIM/Kartu Keanggotaan Organisasi)</span
                        >
                        <span v-if="isImageWithKTM && !changeStatusKTM">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                color="red"
                                v-on="on"
                                v-bind="attrs"
                                @click="deleteImageWithKTM"
                              >
                                <v-icon>mdi-delete-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Hapus Foto</span>
                          </v-tooltip>
                        </span>
                        <span class="float-right red--text font-italic caption"
                          >(Wajib)</span
                        >
                        <div class="upload-container">
                          <div
                            class="upload-content"
                            v-if="isImageWithKTM == ''"
                          >
                            <div>Silahkan isi Foto Memegang KTM</div>
                            <br />
                            dengan salah satu cara dibawah
                            <br />
                            <input
                              hidden
                              ref="fileWithKTM"
                              type="file"
                              accept="image/x-png,image/gif,image/jpeg,image/jpg"
                              @change="onChangeWithKTM"
                            />
                            <div class="mt-3">
                              <v-btn
                                elevation="0"
                                class="mr-5"
                                @click="toggleCamera"
                                >Ambil foto via kamera</v-btn
                              >
                              <v-btn
                                elevation="0"
                                @click="$refs.fileWithKTM.click()"
                                >Ambil dari komputer</v-btn
                              >
                            </div>
                          </div>
                          <div class="upload-content-img" v-else>
                            <v-img
                              :src="isImageWithKTM"
                              width="350"
                              height="230"
                              contain
                            >
                            </v-img>
                          </div>
                        </div>
                      </div>
                    </v-col>
                    <v-col class="pb-0">
                      <div class="mb-2">
                        <span class="font-weight-medium"
                          >Nomor Induk Mahasiswa</span
                        >
                        &nbsp;
                        <span class="text--secondary">(NIM)</span>
                        <span class="float-right font-italic red--text caption"
                          >(Wajib)</span
                        >
                      </div>
                      <v-text-field
                        class="py-0"
                        v-model="nim"
                        placeholder="Tuliskan nomor induk mahasiswa disini"
                        :readonly="changeRead"
                        counter="20"
                        required
                        outlined
                        hide-details="auto"
                        :rules="nimRules"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0">
                      <div class="mb-2">
                        <span class="font-weight-medium">Email</span>
                      </div>
                      <v-text-field
                        class="py-0"
                        v-model="user.email"
                        placeholder="Masukkan alamat email"
                        counter="30"
                        required
                        outlined
                        readonly
                        hide-details="auto"
                        :rules="emailRules"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0">
                      <div class="mb-2">
                        <span class="font-weight-medium">Nama Lengkap</span>
                        <span class="text--secondary">(Tanpa gelar)</span>
                      </div>
                      <v-text-field
                        class="py-0"
                        v-model="fullname"
                        placeholder="Tuliskan nama lengkap"
                        counter="100"
                        :readonly="changeRead"
                        required
                        outlined
                        hide-details="auto"
                        :rules="nameRules"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0">
                      <div class="mb-2">
                        <div class="font-weight-medium">
                          No. Telp
                          <span class="font-italic teal--text caption"
                            >(Optional)</span
                          >
                        </div>
                      </div>
                      <v-text-field
                        class="py-0"
                        v-model="phone"
                        placeholder="Tuliskan No. Telp"
                        counter="15"
                        type="number"
                        :readonly="changeRead"
                        outlined
                        hide-details="auto"
                        >
                        <!-- :rules="phoneRules" -->
                      </v-text-field>
                    </v-col>
                    <v-col class="pb-0">
                      <div class="mb-2">
                        <span class="font-weight-medium">Tempat Lahir</span>
                      </div>
                      <v-text-field
                        class="py-0"
                        v-model="tempat_lahir"
                        placeholder="Masukkan tempat lahir"
                        :readonly="changeRead"
                        counter="50"
                        outlined
                        hide-details="auto"
                        :rules="placeRules"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0">
                      <div class="mb-2">
                        <span class="font-weight-medium"
                          >Tanggal Lahir
                          <span class="text--secondary"
                            >(Format: YYYY-MM-DD)</span
                          ></span
                        >
                      </div>

                      <v-text-field
                        v-if="user.is_updated_profile === true"
                        v-model="date"
                        :rules="dateRules"
                        :readonly="changeRead"
                        outlined
                      ></v-text-field>
                      <v-menu
                        v-if="user.is_updated_profile === false"
                        ref="menus"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            :rules="dateRules"
                            :readonly="
                              changeRead || user.is_updated_profile === true
                            "
                            required
                            v-bind="attrs"
                            placeholder="YYYY-MM-DD"
                            append-icon="ri-calendar-line"
                            outlined
                            v-on="on"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          ref="menu"
                          @input="menu = false"
                          :active-picker.sync="activePicker"
                          :max="
                            new Date(
                              Date.now() -
                                new Date().getTimezoneOffset() * 60000
                            )
                              .toISOString()
                              .substr(0, 10)
                          "
                          min="1950-01-01"
                          @change="save"
                        >
                        </v-date-picker>
                      </v-menu>

                      <!-- <v-text-field
                        v-if="this.user.is_updated_profile === true"
                        v-model="date"
                        :rules="dateRules"
                        :readonly="changeRead"
                        outlined
                      ></v-text-field>
                      <v-menu
                        v-else-if="this.user.is_updated_profile === false"
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        elevation="0"
                      >
                        <v-date-picker
                          ref="picker"
                          v-model="date"
                          :max="new Date().toISOString().substr(0, 10)"
                          min="1950-01-01"
                          @change="save"
                          outlined
                          :rules="dateRules"
                          :readonly="changeRead"
                          required
                        ></v-date-picker>
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            append-icon="ri-calendar-line"
                            :rules="dateRules"
                            :readonly="changeRead"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            placeholder="YYYY-MM-DD"
                          ></v-text-field>
                        </template>
                      </v-menu> -->
                    </v-col>
                    <v-col style="display: flex; place-items: center;">
                      <v-checkbox v-model="acceptPolicy"></v-checkbox>
                      <div> Saya menyetujui <router-link to="/privacy-policy">Kebijakan Privasi</router-link></div>
                    </v-col>

                    <v-btn
                     v-if="acceptPolicy"
                      large
                      elevation="0"
                      color="primary"
                      class="ms-3"
                      :disabled="(changeStatus || !valid )"
                      @click="dialog.confirm = true"
                      >
                      Simpan
                    </v-btn>
                    <v-btn
                      v-else
                      large
                      elevation="0"
                      color="primary"
                      class="ms-3"
                      :disabled="!acceptPolicy"
                      @click="dialog.confirm = true"
                      >
                      Simpan
                    </v-btn>
                  </v-form>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item v-show="showEmail">
              <v-row>
                <v-col cols="12" md="2" class="">
                  <div class="text-center profile ma-auto">
                    <div
                      class="mb-3 pa-10 bg-grey rounded-circle d-inline-block fs-20"
                    >
                      <v-icon>ri-user-line</v-icon>
                    </div>
                  </div>
                </v-col>
                <v-col cols="" md="7" class="ps-2">
                  <v-alert
                    class="ms-4"
                    type="success"
                    text
                    v-show="resSuccess !== null"
                    >{{ resSuccess }}</v-alert
                  >
                  <v-alert
                    class="ms-4"
                    type="error"
                    text
                    v-show="resFailed !== null"
                    >{{ resFailed }}</v-alert
                  >
                  <v-form
                    class="pb-5 ps-2"
                    ref="formChange"
                    v-model="validPsw"
                    lazy-validation
                  >
                    <v-col class="mb-3">
                      <div class="mb-2">
                        <span class="font-weight-medium"
                          >Password Sekarang</span
                        >
                      </div>
                      <v-text-field
                        class=""
                        v-model="current_psw"
                        placeholder="Masukkan password lama anda"
                        required
                        :rules="current_rules"
                        ref="input"
                        outlined
                        hide-details="auto"
                        :type="show ? 'text' : 'password'"
                        :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                        @click:append="show = !show"
                      ></v-text-field>
                    </v-col>
                    <v-col class="mb-3">
                      <div class="mb-2">
                        <span class="font-weight-medium">Password Baru</span>
                      </div>
                      <v-text-field
                        class=""
                        v-model="new_psw"
                        placeholder="Masukkan password baru anda"
                        required
                        :rules="new_rules"
                        ref="input"
                        outlined
                        hide-details="auto"
                        :type="show2 ? 'text' : 'password'"
                        :append-icon="show2 ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                        @click:append="show2 = !show2"
                      ></v-text-field>
                    </v-col>
                    <v-col class="mb-3">
                      <div class="mb-2">
                        <span class="font-weight-medium"
                          >Konfirmasi Password</span
                        >
                      </div>
                      <v-text-field
                        class=""
                        v-model="confirm_psw"
                        placeholder="Masukkan ulang password anda"
                        required
                        :rules="confirm_rules"
                        ref="input"
                        outlined
                        hide-details="auto"
                        :type="show3 ? 'text' : 'password'"
                        :append-icon="show3 ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                        @click:append="show3 = !show3"
                      ></v-text-field>
                    </v-col>
                    <v-btn
                      large
                      elevation="0"
                      :disabled="!confirm_psw"
                      color="primary"
                      class="ms-3"
                      @click="changePassword()"
                    >
                      Update
                    </v-btn>
                    <!-- </v-col> -->
                    <!-- </v-row> -->
                  </v-form>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-card-text>
      <v-dialog v-model="dialog.confirm" width="500">
        <v-card>
          <v-card-title class="font-weight-medium fs-24 color-state">
            Perhatian
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-1 fs-14">
            <span class="color-disable"
              >Apakah informasi yang anda masukkan sudah sesuai dan benar
              adanya, jika iya silahkan klik benar
            </span>
            <v-alert dense text color="warning" class="px-1 fs-12">
              <v-icon color="warning">mdi-information-outline</v-icon>
              <strong> Data yang sudah disimpan tidak dapat diubah. </strong>
            </v-alert>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              outlined
              class="py-6 fs-18 pa-4"
              @click="dialog.confirm = false"
            >
              Batalkan
            </v-btn>
            <v-btn
              color="primary"
              class="py-6 fs-18 pa-4"
              @click="saveSetting()"
            >
              Benar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog.photoTaken" width="500">
        <v-card>
          <v-card-title> Ambil Foto Memegang KTM </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div v-show="isCameraOpen && isLoading" class="camera-loading">
              <ul class="loader-circle">
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>

            <!-- IS CAMERA STREAM -->
            <div
              v-if="isCameraOpen"
              v-show="!isLoading"
              class="camera-box"
              :class="{ flash: isShotPhoto }"
            >
              <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>

              <video
                v-show="!isPhotoTaken"
                ref="camera"
                :width="450"
                :height="337.5"
                autoplay
              ></video>
              <img
                v-if="isImageWithKTM != ''"
                :src="isImageWithKTM"
                alt="gambar"
                :width="450"
                :height="337.5"
              />
              <canvas
                v-show="isPhotoTaken"
                id="photoTaken"
                ref="canvas"
                :width="450"
                :height="337.5"
              ></canvas>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center d-flex align-center">
            <v-btn elevation="0" @click="toggleCamera">Batalkan</v-btn>
            <v-btn
              v-if="isCameraOpen && !isLoading"
              elevation="0"
              color="primary"
              class="ms-3"
              @click="takePhoto()"
            >
              <v-icon left>mdi-camera</v-icon>
              {{ isImageWithKTM == "" ? "Ambil Foto" : "Ambil Ulang" }}
            </v-btn>
            <v-btn
              v-if="isPhotoTaken && isCameraOpen"
              elevation="0"
              color="blue"
              class="ms-3 white--text"
              @click="saveImage"
            >
              <v-icon left>mdi-content-save</v-icon>
              Simpan Foto
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "account-setting",
  data() {
    return {
      meta: {
        ktm: '',
        ktm_user: ''
      },
      acceptPolicy: false,
      pageLoading: false,
      // GET FORO VIA FILE
      file: [],
      draging: false,
      filepreview: "",
      fileUpload: [],
      // GET FOTO VIA KAMERA
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      isImageKTM: "",
      isImageWithKTM: "",
      // GET FOTO VIA KAMERA END
      activePicker: null,
      showEmail: false,
      show: false,
      show2: false,
      show3: false,
      tab: null,
      valid: true,
      menu: false,
      date: null,
      fullname: "",
      email: "",
      tempat_lahir: "",
      phone: "",
      nim: "",
      nimRules: [(v) => !!v || "Nomor Induk Mahasiswa Tidak Boleh Kosong"],
      nameRules: [(v) => !!v || "Nama Lengkap Tidak Boleh Kosong"],
      phoneRules: [
        (v) =>
          !!v.length < 15 ||
          "Nomor Telphone terlalu banyak, minimal 15 karakter",
      ],
      emailRules: [
        (v) => !!v || "E-mail Tidak Boleh Kosong",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      placeRules: [(v) => !!v || "Tempat Lahir Tidak Boleh Kosong"],
      dateRules: [
        (v) => {
          if (!v) return "Tanggal Lahir Tidak Boleh Kosong";
          const fullDate = v.split("-");
          const year = fullDate[0];
          const month = fullDate[1];
          const day = fullDate[2];

          if (parseInt(year) < 1900)
            return "Tahun tidak boleh kurang dari 1900";
          if (!parseInt(year) || year.length != 4)
            return "Format tanggal tidak valid";
          if (!parseInt(month) || month.length != 2)
            return "Format tanggal tidak valid";
          if (!parseInt(day) || day.length != 2)
            return "Format tanggal tidak valid";

          if (!Date.parse(`${year}-${month}-${day}`)) {
            return "Format tanggal tidak valid";
          }

          return true;
        },
      ],

      validPsw: true,
      current_psw: "",
      new_psw: "",
      confirm_psw: "",
      current_rules: [
        (v) => !!v || "Password Tidak Boleh Kosong",
        (v) => (v && v.length >= 8) || "Password minimal harus 8 karakter",
      ],
      new_rules: [
        (v) => !!v || "Password Tidak Boleh Kosong",
        (v) => (v && v.length >= 8) || "Password minimal harus 8 karakter",
      ],

      submiting: false,
      dialog: {
        confirm: false,
        photoTaken: false,
      },
      response: null,
      responsesukses: null,
      resSuccess: null,
      resFailed: null,
      changeStatusKTM: false,
      changeRead: false,
      changeStatus: false,
      detail: {},
    };
  },
  components: {},
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.menu.activePicker = "YEAR"));
    },
    // menu (val) {
    //   val && setTimeout(() => (this.activePicker = 'YEAR'))
    // },
    fullname(value) {
      this.fullname = value
        .split(" ")
        .map((val) => {
          return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
        })
        .join(" ");
    },
  },
  computed: {
    ...mapGetters(["user", "isLoggedIn"]),
    confirm_rules() {
      return [
        () =>
          this.new_psw === this.confirm_psw || "Konfirmasi password tidak sama",
        (v) => !!v || "Konfirmasi Password Tidak Boleh Kosong",
      ];
    },
  },
  async created() {
    // console.log('token',this.user.is_updated_profile);
    
    // this.changePsw();
  },
  async mounted() {
    await this.loginWithPotensa();
    await this.getDetail();
  },
  methods: {
    async loginWithPotensa() {
      if (!this.isLoggedIn) {
        const token = this.$route.query.token;
        if (!token) this.$router.replace("/");
        this.pageLoading = true;
        await this.$store
          .dispatch("loginWithPotensa", token)
          .catch(() => {
            console.log('error cuk!');
            this.$router.replace("/");
          })
          .finally(() => {
            this.pageLoading = false;
          });
      }

      setTimeout(() => {
        if (this.user) {
          this.email = this.user.email;
          this.fullname = this.user.fullname;
        }
      }, 5e2);

      if (this.$route.query.token) {
        this.$router.replace("/account");
      }
    },
    async copyContent() {
      let text = document.getElementById("myText").innerHTML;
      try {
        await navigator.clipboard.writeText(text);
        console.log("Content copied to clipboard");
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    },
    // ====================================================
    // ============== GET FOTO VIA KAMERA =================
    // ====================================================
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.dialog.photoTaken = false;
        this.isImageWithKTM = "";
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.isImageWithKTM = "";
        this.dialog.photoTaken = true;
        this.createCameraElement();
      }
    },
    deleteImageWithKTM() {
      this.isImageWithKTM = "";
    },
    deleteImageKTM() {
      this.isImageKTM = "";
    },
    saveImage() {
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.dialog.photoTaken = false;
      this.stopCameraStream();
    },
    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          alert("May the browser didn't support or there is some errors.");
        });
    },
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },
    takePhoto() {
      this.isLoading = true;
      if (this.isImageWithKTM != "") {
        this.createCameraElement();
        this.isImageWithKTM = "";
        this.isPhotoTaken = false;
      } else {
        if (!this.isPhotoTaken) {
          this.isShotPhoto = true;

          const FLASH_TIMEOUT = 50;

          setTimeout(() => {
            this.isShotPhoto = false;
          }, FLASH_TIMEOUT);
        }

        this.isPhotoTaken = true;
        let canvas = document.createElement("canvas"); //document.getElementById("photoTaken"); //this.$refs.canvas;
        let context = canvas.getContext("2d");
        let vid = this.$refs.camera;
        canvas.width = vid.width;
        canvas.height = vid.height;
        context.drawImage(vid, 0, 0, 450, 337.5);

        canvas.toBlob((blob) => {
          let form_data = new FormData();
          form_data.append("title", `Foto-Memegang-KTM-${this.fullname}`);
          form_data.append("source", "upload");
          form_data.append(
            "file",
            blob,
            `Foto-Memegang-KTM-${this.fullname}.jpg`
          );
          this.$store
            .dispatch("media/create", form_data)
            .then((res) => res.data)
            .then((data) => {
              this.isImageWithKTM = data.public_url;
              this.isLoading = false;
            });
        }, "image/jpeg");
      }
    },
    // ====================================================
    // ============== GET FOTO VIA KAMERA END =============
    // ====================================================

    // ====================================================
    // ============== GET FOTO VIA FILE =================
    // ====================================================
    onChange(e) {
      let src = {
        url: "",
        title: "",
        alt: "",
        description: "",
        caption: "",
      };

      var files = e.target.files || e.dataTransfer.files;
      let selectedFiles = e.target.files[0] || e.dataTransfer.files[0];

      if (this.draging) {
        this.draging = false;
        src.url = URL.createObjectURL(selectedFiles);
        src.src = URL.createObjectURL(selectedFiles);
        src.title = selectedFiles.name;
        src.alt = selectedFiles.name;
        src.file = selectedFiles;
      } else {
        src.url = URL.createObjectURL(e.target.files[0]);
        src.src = URL.createObjectURL(e.target.files[0]);
        src.title = e.target.files[0].name;
        src.alt = e.target.files[0].name;
        src.file = e.target.files[0];
      }

      src.source = "upload";
      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.fileUpload = files;

      this.setAttachment(src, "KTM");
    },
    onChangeWithKTM(e) {
      let src = {
        src: null,
        title: null,
        alt: null,
        description: null,
        caption: null,
      };
      // var files = e.target.files || e.dataTransfer.files;

      src.url = URL.createObjectURL(e.target.files[0]);
      src.title = e.target.files[0].name;
      src.alt = e.target.files[0].name;
      src.file = e.target.files[0];
      src.source = "upload";

      this.setAttachment(src, "withKTM");
    },
    setAttachment(file, status) {
      let formData = new FormData();
      formData.append("source", file.source);
      formData.append("alt", file.alt);
      formData.append("caption", file.caption);
      formData.append("description", file.description);
      formData.append("file", file.file);
      formData.append("title", `${file.title}`);
      formData.append("url", file.url);

      this.$store
        .dispatch("media/create", formData)
        .then((res) => res.data)
        .then((data) => {
          if (status == "withKTM") {
            this.isImageWithKTM = data.public_url;
            this.meta.ktm_user = data.id
          } else {
            this.isImageKTM = data.public_url;
            this.meta.ktm = data.id
          }
          this.isLoading = false;
        });
    },
    // ====================================================
    // ============== GET FOTO VIA FILE =================
    // ====================================================
    save(date) {
      this.$refs.menu.save(date);
    },
    // changePsw(){
    //   if (this.user.provider == 'email') {
    //     this.showEmail=true;
    //   }else{
    //     this.showEmail=false;
    //   }
    // },
    getDetail() {
      this.axios.get(`/users/v1/member/detail/`).then((response) => {
        let res = response.data;
        // console.log(res);
        this.loading = false;
        if (response.status == 200) {
          this.loading = false;
          this.detail = res.data;
          // console.log('detail',this.detail);
          this.getDisable();
        } else if (response.status == 400) {
          console.log("hasil2", response);
        }
      });
    },
    getDisable() {
      if (this.detail.is_updated_profile === true) {
        if(this.detail.meta.ktm !== "" && this.detail.meta.ktm_user !== "") {
          this.changeStatusKTM = true; 
          this.changeStatus = true;
        }
        this.changeRead = true;
        this.fullname = this.detail.fullname;
        this.date = this.detail.date_of_birth;
        this.tempat_lahir = this.detail.place_of_birth;
        this.phone = this.detail.phone_number;
        this.nim = this.detail.meta.nim;
        this.isImageKTM = this.detail.meta.ktm;
        this.isImageWithKTM = this.detail.meta.ktm_user;
        return;
      }
      this.email = this.user.email;
    },
    saveSetting() {
      this.dialog.confirm = false;
      // if (!this.$refs.form.validate()) return
      console.log("foto ktm", this.isImageKTM);
      
      this.submiting = true;
      if (this.isImageKTM == "" || this.isImageWithKTM == "") {
        window.scrollTo(0, 0);
        this.dialog.confirm = false;
        this.submiting = false;
        this.response =
          "Foto Kartu Identitas atau Foto Memegang Kartu Identitas belum lengkap";
        setTimeout(() => {
          this.response = null;
        }, 5000);
        return;
      }
      const sk = this.$refs.form.validate();
      console.log("sk", sk);
      if (sk == true) {
        
        this.submiting = false;
        this.changeRead = true;
        let fullname = this.fullname;
        let date_of_birth = this.date;
        let place_of_birth = this.tempat_lahir;
        let phone = this.phone;
        let meta = {
          nim: this.nim,
          ktm: this.isImageKTM,
          ktm_user: this.isImageWithKTM,
        };

        if(this.detail.is_updated_profile){
          this.handleUpdateKTMMeta(this.meta);

        }else {
          this.handleUpdateProfile({ fullname, date_of_birth, place_of_birth, phone, meta })
        }

        
      } else {
        window.scrollTo(0, 0);
        this.submiting = false;
        this.changeRead = false;
        this.email = this.user.email;
        this.fullname = "";
        this.date = null;
        this.tempat_lahir = "";
        this.response = "Silahkan cek kembali data Anda";
        setTimeout(() => {
          this.response = null;
        }, 3000);
      }
    },
    async handleUpdateKTMMeta(meta) {
      await this.axios
      .post(
        `/users/v1/member/update-ktm`,
        meta,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        let res = response.data;
        console.log(res);
        this.loading = false;

        this.$store.dispatch("get_user");
        this.loading = false;
        this.responsesukses = "Data Anda berhasil disimpan";
        this.getDetail();
        setTimeout(() => {
          this.responsesukses = null;
          this.$router.push("/epot");
        }, 3000);
      })
      .catch((error) => {
        if (error.response) {
          let errorCode = error.response.status;
          let errorData = error.response.data.validation;

          if (errorCode == 400) {
            window.scrollTo(0, 0);
            this.submiting = false;
            this.changeRead = false;
            this.email = this.user.email;
            this.response = "";
            Object.keys(errorData).forEach((item) => {
              this.response += errorData[item] + `</br>`;
            });
            setTimeout(() => {
              this.response = null;
            }, 5000);
          }
        }
      });
    },
    async handleUpdateProfile(data) {
      await this.axios
      .put(
        `/users/v1/member/update-profile/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        let res = response.data;
        console.log(res);
        this.loading = false;

        this.$store.dispatch("get_user");
        this.loading = false;
        this.responsesukses = "Data Anda berhasil disimpan";
        this.getDetail();
        setTimeout(() => {
          this.responsesukses = null;
          this.$router.push("/epot");
        }, 3000);
      })
      .catch((error) => {
        if (error.response) {
          let errorCode = error.response.status;
          let errorData = error.response.data.validation;

          if (errorCode == 400) {
            window.scrollTo(0, 0);
            this.submiting = false;
            this.changeRead = false;
            this.email = this.user.email;
            this.response = "";
            Object.keys(errorData).forEach((item) => {
              this.response += errorData[item] + `</br>`;
            });
            setTimeout(() => {
              this.response = null;
            }, 5000);
          }
        }
      });
    },
    changePassword() {
      this.submiting = true;
      if (this.$refs.formChange.validate()) {
        this.submiting = false;
        let current_password = this.current_psw;
        let new_password = this.new_psw;
        let conf_new_password = this.confirm_psw;
        // this.$refs.formChange.reset();
        // console.log('result change', {current,newpass,confirm});

        this.axios
          .put(
            `/users/v1/member/change-password/`,
            { current_password, new_password, conf_new_password },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            let res = response.data;
            console.log("change", response);
            this.loading = false;
            if (response.status == 200) {
              this.loading = false;
              console.log("sukses", res.data);
              this.resSuccess = "Password Anda berhasil diubah ";
              setTimeout(() => {
                this.resSuccess = null;
              }, 3000);
              let email = this.user.email;
              let password = this.new_psw;
              // console.log('password',password)
              this.$store.dispatch("login", { email, password }).then(() => {
                this.submiting = false;
                if (localStorage.getItem("ytc_lms_redirect") != null) {
                  this.$router.push(localStorage.getItem("ytc_lms_redirect"));
                  localStorage.removeItem("ytc_lms_redirect");
                }
              });
            }
          })
          .catch(() => {
            this.submiting = false;
            this.$refs.form.reset();
            this.resFailed = "Current password Anda salah";
            setTimeout(() => {
              this.resFailed = null;
            }, 3000);
          });
      } else if (
        this.new_password.length < 8 ||
        this.conf_new_password.length < 8
      ) {
        this.resFailed = "Password kurang dari 8 character";
        this.submiting = false;
        setTimeout(() => {
          this.resFailed = null;
        }, 3000);
      } else if (this.conf_new_password != this.new_password) {
        this.resFailed = "Password kurang dari 8 character";
        this.submiting = false;
        setTimeout(() => {
          this.resFailed = null;
        }, 3000);
      } else {
        this.submiting = false;
        this.resFailed = "Kolom password tidak boleh kosong";
        this.$refs.form.reset();
        setTimeout(() => {
          this.resFailed = null;
        }, 3000);
      }
      this.$refs.formChange.reset();
    },
  },
};
</script>

<style lang="scss">
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
.accent-blue {
  background-color: #d4e5fe !important;
  /*opacity: 0.4;*/
  border-radius: 4px;
}
.upload-container {
  width: 100%;
  height: 250px;
  border: 2px dashed #ddd;
  margin-top: 10px;
}
.upload-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 250px;
}

.upload-content-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}

.camera-box {
  .camera-shutter {
    opacity: 0;
    width: 450px;
    height: 337.5px;
    background-color: #fff;
    position: absolute;

    &.flash {
      opacity: 1;
    }
  }
}
.camera-shoot {
  margin: 1rem 0;

  button {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    img {
      height: 35px;
      object-fit: cover;
    }
  }
}
.camera-loading {
  overflow: hidden;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 450px;
  height: 250px;
  margin: 3rem 0 0 0;

  ul {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 999999;
    margin: 0;
  }

  .loader-circle {
    display: block;
    height: 14px;
    margin: 0 auto;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    transform: translateX(-50%);
    position: absolute;
    width: 100%;
    padding: 0;

    li {
      display: block;
      float: left;
      width: 10px;
      height: 10px;
      line-height: 10px;
      padding: 0;
      position: relative;
      margin: 0 0 0 4px;
      background: #999;
      animation: preload 1s infinite;
      top: -50%;
      border-radius: 100%;

      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }
}

@keyframes preload {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.dropZone {
  width: 100%;
  height: 250px;
  position: relative;
  border: 2px dashed #eee;
  margin-top: 10px;
}

.dropZone:hover {
  border: 3px dashed #652065;
}

.dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}
</style>